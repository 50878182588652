.aero-titulo{
    font-size: 40px;
    color: #005c6e;
    font-family: "Noto Sans";
    font-weight: bold;
}

.aero-imagens{
    max-width: 360px;
}

.aero-p {
    font-family: Cambria;
    font-size: 22px;
  }

.aero-subtitulo{
    font-size: 35px;
    font-weight: bold;
}

.aero-fonte {
    font-family: Cambria;
    font-size: 22px;
    font-style: italic ;

  }