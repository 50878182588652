.centrodememoria-titulo{
    font-size: 40px;
    color: #005c6e;
    font-family: "Noto Sans";
    font-weight: bold;
}

.centrodememoria-p{
    font-family: Cambria;
    font-size: 22px;
}

.centrodememoria-p2{
    font-family: Cambria;
    font-size: 18px;
}

.centrodememoria-imagens{
    max-width: 700px;
}

.centrodememoria-subtitulo{
    font-size: 30px;
    font-weight: bold;
}

.centrodememoria-subtitulo2{
    font-size: 25px;
    font-weight: bold;
}

.centrodememoria-a{
    font-size: 25px;
}

.centrodememoria-a2{
    font-size: 18px;
}