.manut-titulo {
  font-size: 40px;
  color: #005c6e;
  font-family: "Noto Sans";
  font-weight: bold;
}

.manut-subtitulo {
  font-family: Noto Sans;
  font-weight: lighter;
  font-size: 20px;
}

.manut-subtitulo2 {
  color: #005c6e;
  font-family: Noto Sans;
  font-weight: bold;
  font-size: 36px;
}

.manut-card-titulo {
  color: #2bb8c9;
  font-weight: bold;
}
.manut-card-texto {
  font-family: "Cambria";
  font-size: 23px;
}

.manut-links {
  text-decoration: none;
  color: aquamarine;
}

.manut-card-link-titulo {
  font-family: "Lucida Sans";
  font-weight: bolder;
}
.manut-card-link-subtitulo {
  font-family: "Lucida Sans";
  font-size: 14;
  font-weight: lighter;
}

.manut-card-botao {
  font-family: "Lucida Sans";
}

.manut-card-descricao {
  text-align: justify;
  font-family: "Cambria";
  font-size: 23px;
}

.manut-a{
    font-size: 22px;
}
