.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  gap: 1.5rem;
  padding: 0rem;
  align-items: center;
}

.item img,
video {
  width: auto;
  min-height: 500px;
  max-height: 500px;
  margin: 0 auto;
  display: block;
  margin-top: 50px;
  margin-bottom: 130px;
  border: 0;
  object-fit: cover;
}

.carousel {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.image {
  border-radius: 1rem;
  overflow: hidden;
  transition: 0.4s;
  box-shadow: 1px 2px 9px black;
}
.image:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: 0.4s ease;
}
