.aula-titulo{
    font-size: 40px;
    color: #005c6e;
    font-family: "Noto Sans";
    font-weight: bold;
}

.aula-p{
    font-family: Cambria;
    font-size: 22px;
}

.aulas-imagens{
    max-width: 700px;
}

.aula-subtitulo{
    font-size: 35px;
    font-weight: bold;
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

#horario{
    font-size: 25px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

#cor_aulas{
    color: red;
  font-size: 17px;
  text-decoration: none;
}