#espaco {
  margin-bottom: 5%;
}

.concurso-card {
  padding: 3%;
  margin-bottom: 5%;
  font-family: Cambria;
  border: 0;
}

.titulo {
  font-size: 40px;
  color: #005c6e;
  font-family: "Noto Sans";
  font-weight: bold;
}

#subtitulo {
  font-size: 25px;
  font-weight: bold;
}

#left {
  margin-left: 5%;
  margin-bottom: 4%;
  font-size: 17px;
  color: red;
}

#left0 {
  margin-left: 0%;
  margin-bottom: 1%;
  font-size: 17px;
  color: red;
}
