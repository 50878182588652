.email-titulo{
    font-size: 40px;
    color: #005c6e;
    font-family: "Noto Sans";
    font-weight: bold;
}

.email-subtitulo{
    font-size: 35px;
    font-weight: bold;
}

.email-p2{
    font-family: Cambria;
    font-size: 22px;
    color: #696969;
    font-weight: bold;
}

.email-p {
    font-family: Cambria;
    font-size: 22px;
  }

.email-imagens{
    max-width: 700px;
}