.vestec-imagens{
    max-width: 360px;
}

.vestec-p {
    font-family: Cambria;
    font-size: 22px;
  }

.vestec-subtitulo{
    font-size: 35px;
    font-weight: bold;
}