.baja-titulo{
    font-size: 40px;
    color: #005c6e;
    font-family: "Noto Sans";
    font-weight: bold;
}

.baja-imagens{
    max-width: 360px;
}

.baja-imagens2{
    max-width: 200px;
}

.baja-imagens3{
    max-width: 550px;
}

.baja-p {
    font-family: Cambria;
    font-size: 22px;
  }

.baja-subtitulo{
    font-size: 35px;
    font-weight: bold;
}

#cor{
    text-decoration: none;
}