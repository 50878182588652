.sbr-card-titulo {
  color: #2bb8c9;
  font-weight: bold;
}

.sbr-titulo {
  font-size: 40px;
  color: #005c6e;
  font-family: "Noto Sans";
  font-weight: bold;
}

.sbr-list-titulo {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 23px;
}

.sbr-links {
  color: black;
  text-decoration: none;
}

.sbr-inscricoes {
  color: black;
  font-size: 30px;
  text-decoration: none;
  font-weight: 100;
  font-family: "Cambria";
}
.sbr-guia {
  margin-right: 5px;
  font-size: 22px;
  color: black;
  text-decoration: none;
  font-family: "Cambria";
}

.sbr-card-descricao {
  text-align: justify;
  font-family: "Cambria";
  font-size: 23px;
}
