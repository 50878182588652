.cipa_titulo {
  font-family: Cambria;
  font-size: 22px;
}

#cor {
  color: red;
  font-size: 22px;
}

.cipa-p {
  font-family: Cambria;
  font-size: 22px;
}

.cipa-h2 {
  font-family: Cambria;
  font-weight: bold;
}

.link {
  text-decoration: none;
  color: darkblue;
  font-size: 22px;
}

.cipa {
  font-size: 40px;
  color: black;
  font-family: "Cambria";
  font-weight: bold;
}
