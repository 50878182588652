.edit-titulo {
  font-size: 40px;
  color: #005c6e;
  font-family: "Noto Sans";
  font-weight: bold;
}

#subtitulo {
  font-size: 35px;
  font-weight: bold;
  font-family: Cambria;
}

#cor {
  color: red;
  /*font-size: 22px;*/
}
