.bbl-titulo {
  font-size: 40px;
  color: #005c6e;
  font-family: "Noto Sans";
  font-weight: bold;
}

#subtitulo {
  font-size: 35px;
  font-weight: bold;
}

#marca {
  background-color: #00ff00;
  color: #000000;
}

#espaco {
  margin-bottom: 10%;
  font-family: Cambria;
}
