.ads-titulo {
  font-size: 40px;
  color: #005c6e;
  font-family: "Noto Sans";
  font-weight: bold;
}

.ads-subtitulo {
  font-family: Noto Sans;
  font-weight: lighter;
  font-size: 20px;
}

.ads-card-titulo {
  color: #2bb8c9;
  font-weight: bold;
}
.ads-card-texto {
  font-family: "Cambria";
  font-size: 23px;
}

.ads-links {
  text-decoration: none;
  color: aquamarine;
}

.ads-card-link-titulo {
  font-family: "Lucida Sans";
  font-weight: bolder;
}
.ads-card-link-subtitulo {
  font-family: "Lucida Sans";
  font-size: 14;
  font-weight: lighter;
}

.ads-card-botao {
  font-family: "Lucida Sans";
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ads-card-descricao {
  text-align: justify;
  font-family: "Cambria";
  font-size: 23px;
}
