.log-titulo {
  font-size: 40px;
  color: #005c6e;
  font-family: "Noto Sans";
  font-weight: bold;
}

.log-subtitulo {
  font-family: Noto Sans;
  font-weight: lighter;
  font-size: 20px;
}

.log-card-titulo {
  color: #2bb8c9;
  font-weight: bold;
}
.log-card-texto {
  font-family: "Cambria";
  font-size: 23px;
}

.log-links {
  text-decoration: none;
  color: aquamarine;
}

.log-card-link-titulo {
  font-family: "Lucida Sans";
  font-weight: bolder;
}
.log-card-link-subtitulo {
  font-family: "Lucida Sans";
  font-size: 14;
  font-weight: lighter;
}

.log-card-botao {
  font-family: "Lucida Sans";
}

.log-card-descricao {
  text-align: justify;
  font-family: "Cambria";
  font-size: 23px;
}
