.color-nav {
  background-color: #b20000;
  justify-content: space-between;
}

/*sub menu*/
.dp-menu ul ul {
  display: none;
  left: 0;
  position: absolute;
}

.dp-menu ul li:hover ul {
  display: block;
}

.dp-menu ul ul {
  background-color: gray;
  width: 250px;
}

.dp-menu ul ul li a {
  display: block;
}

a {
  font-size: 13px;
  font-weight: bold;
}

ul {
  display: table;
  width: 100%;
  text-align: center;
}

#centro {
  display: flex;
  justify-content: center;
}

#submenu {
  background-color: #005c6e;
  height: 40px;
  text-align: center;
  padding-top: 6px;
  display: relative;
  justify-content: space-around;
}

#predio {
  width: 100%;
  margin-top: 0px;
  z-index: -10;
}

#estilo {
  color: white;
  margin-top: 10px;
  text-decoration: none;
}

.z-index-master {
  position: relative;
  z-index: 10;
}
