.cadi-p {
  font-family: Cambria;
  font-size: 22px;
}

.cadi-h2 {
  font-family: Cambria;
  font-weight: bold;
}
.cadi-imagens {
  max-width: 360px;
}

.cadi-link {
  text-decoration: none;
  color: darkblue;
  font-size: 22px;
}
