.corpo-adm-nome {
  font-size: 22px;
}

.corpo-adm-setor {
  font-size: 18px;
}

.corpo-adm-curso {
  font-size: 18px;
  text-decoration: none;
}

.corpo-adm-h2 {
  font-family: "Cambria";
  font-weight: bold;
}
.corpo-adm-imagens {
  max-width: 360px;
}

.corpo-adm-link {
  text-decoration: none;
  color: darkblue;
  font-size: 18px;
}

.corpo-adm-titulo {
  font-size: 40px;
  color: #005c6e;
  font-family: "Noto Sans";
  font-weight: bold;
}

#cor {
  color: red;
  font-size: 18px;
}
