.setordeestagios-titulo {
    font-size: 30px;
    color: #005c6e;
    font-family: "Noto Sans";
    font-weight: bold;
  }
  
  #subtitulo {
    font-size: 25px;
    font-weight: bold;
  }
  
  #espaco {
    margin-bottom: 5%;
  }
  
  #bold {
    font-weight: bold;
    font-size: 20px;
  }
  
  #cor {
    color: red;
    font-size: 22px;
  }
  